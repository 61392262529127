import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Tabs, Table, Button, Modal, Input, Tag, Tooltip, DatePicker, Select, Checkbox,Upload ,Card, Row, Col} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, DeleteOutlined, CheckOutlined,QrcodeOutlined, MailOutlined, PlusOutlined,UploadOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, Tooltip as GraphTooltip, CartesianGrid, ResponsiveContainer } from 'recharts'; // Import Recharts
import dayjs from 'dayjs'; // Thai locale
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/th';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import '../../styles/eventPage.css';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('th');

const { RangePicker } = DatePicker;
const { Option } = Select;

const EventPage = ({ userRole }) => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isTableEditing, setIsTableEditing] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [editableEvent, setEditableEvent] = useState({});
  const [originalBanner, setOriginalBanner] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showEditConfirm, setShowEditConfirm] = useState(false);
  const [showVIPModal, setShowVIPModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [vipGuest, setVipGuest] = useState({ name: '', lastName: '', email: '', phone: '', label: '' });
  const [dateRange, setDateRange] = useState(null);
  const [previewBanner, setPreviewBanner] = useState(null);
  const [emailType, setEmailType] = useState('invitation');
  const [labelOptions, setLabelOptions] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState('');
  const [sendToAll, setSendToAll] = useState(false);
  const [updatedUsers, setUpdatedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [checkInSearchTerm, setCheckInSearchTerm] = useState('');

  const [showImportModal, setShowImportModal] = useState(false);
  const [importType, setImportType] = useState('');
  const [duplicateUsers, setDuplicateUsers] = useState([]);
  const [invitationStatusFilter, setInvitationStatusFilter] = useState(null);
  const [scannedCheckIn, setScannedCheckIn] = useState(false);
  const [checkInStatusFilter, setCheckInStatusFilter] = useState('all');
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedRound, setSelectedRound] = useState(null);
  const [selectedFilterLabel, setSelectedFilterLabel] = useState(null);

  const [graphSelectedDay, setGraphSelectedDay] = useState(null);

  
  
  useEffect(() => {
    let scannedInput = '';
  
    const handleBarcodeScan = (event) => {
      if (event.key && event.key.length === 1 && !event.ctrlKey && !event.metaKey && !event.altKey) {
        scannedInput += event.key;
      } else if (event.key === 'Enter') {
        console.log('Scanned QR Code:', scannedInput);
  
        const match = scannedInput.match(/Customer ID:\s*([a-fA-F0-9]{24})/);
        if (match && match[1]) {
          const customerId = match[1];
          console.log('Extracted Customer ID:', customerId);
  
          const user = registeredUsers.find((user) => user._id === customerId);
          if (user) {
            setSelectedUser(user);
            setShowUserInfoModal(true);
            setScannedCheckIn(true); // Mark as a scanned check-in
    
            // Automatically check in the user after setting the state
            setTimeout(() => {
              if (!user.checkedIn) {
                handleCheckIn(user._id);
              }
            }, 300);
          } else {
            toast.error('User not found!');
          }
        } else {
          toast.error('Invalid QR code format!');
        }
  
        scannedInput = '';
      }
    };
  
    window.addEventListener('keydown', handleBarcodeScan);
    return () => {
      window.removeEventListener('keydown', handleBarcodeScan);
    };
  }, [registeredUsers]);
  // useEffect(() => {
  //   let scannedInput = '';
  
  //   const handleBarcodeScan = (event) => {
  //     // Ensure that event.key is defined before accessing its length
  //     if (event.key && event.key.length === 1 && !event.ctrlKey && !event.metaKey && !event.altKey) {
  //       // Accumulate printable characters
  //       scannedInput += event.key;
  //     } else if (event.key === 'Enter') {
  //       console.log('Scanned QR Code:', scannedInput);
  
  //       // Extract Customer ID from the scanned input
  //       const match = scannedInput.match(/Customer ID:\s*([a-fA-F0-9]{24})/);
  //       console.log('match:', match);
  
  //       if (match && match[1]) {
  //         const customerId = match[1];
  //         console.log('Extracted Customer ID:', customerId);
  
  //         // Find the user by Customer ID
  //         const user = registeredUsers.find((user) => user._id === customerId);
  //         if (user) {
  //           setSelectedUser(user);
  //           setShowUserInfoModal(true);
  //         } else {
  //           toast.error('User not found!');
  //         }
  //       } else {
  //         toast.error('Invalid QR code format!');
  //       }
  
  //       // Clear the scanned input after processing
  //       scannedInput = '';
  //     }
  //   };
  
  //   // Add event listener for keydown events
  //   window.addEventListener('keydown', handleBarcodeScan);
  
  //   // Cleanup function
  //   return () => {
  //     window.removeEventListener('keydown', handleBarcodeScan);
  //   };
  // }, [registeredUsers]);
  const generateDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = dayjs(startDate);
    const finalDate = dayjs(endDate);
  
    while (currentDate.isBefore(finalDate) || currentDate.isSame(finalDate)) {
      dates.push(currentDate);
      currentDate = currentDate.add(1, 'day');
    }
  
    return dates;
  };
  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/events/${eventId}`, { withCredentials: true });
        const allDates = generateDateRange(
          response.data.date[0],
          response.data.date[1]
        );
        console.log("Generated dates:", allDates);
        setEvent({ ...response.data, allDates });
        // setEvent(response.data);
        console.log("event",response.data)
        setEditableEvent(response.data);
        setDateRange([dayjs(response.data.startDate), dayjs(response.data.endDate)]);
        setOriginalBanner(response.data.thumbnail);
      } catch (error) {
        toast.error('Error fetching event details!');
      }
    };

    const fetchRegisteredUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/events/${eventId}/registered-users`, { withCredentials: true });
        setRegisteredUsers(response.data);
        console.log("response.data",response.data)
      } catch (error) {
        toast.error('Error fetching registered users!');
      }
    };

    const fetchLabels = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${eventId}/labels`, { withCredentials: true });
        setLabelOptions(response.data);
      } catch (error) {
        toast.error('Error fetching labels');
      }
    };

    fetchEventDetails();
    fetchRegisteredUsers();
    fetchLabels();
  }, [eventId]);
  useEffect(() => {
    console.log("vipGuest",vipGuest)
      }, [vipGuest]);

  const handleDeleteEvent = async (e) => {
    e.stopPropagation();
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/events/${eventId}`, { withCredentials: true });
      toast.success('Event deleted successfully');
      window.location.href = '/event-dashboard';
    } catch (error) {
      toast.error('Error deleting event');
    }
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    if (isEditing) {
      setShowEditConfirm(true);
    } else {
      setIsEditing(true);
    }
  };

  const handleConfirmEdit = async (e) => {
    e.stopPropagation();
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/events/${eventId}`, editableEvent, { withCredentials: true });
      if (previewBanner) {
        await uploadBanner();
      }
      toast.success('Event updated successfully');
      setEvent(editableEvent);
      setIsEditing(false);
      setShowEditConfirm(false);
    } catch (error) {
      toast.error('Error updating event');
    }
  };

  const handleCancelEdit = (e) => {
    e.stopPropagation();
    setEditableEvent(event);
    setPreviewBanner(null);
    setIsEditing(false);
    setShowEditConfirm(false);
  };

  const handleBannerClick = () => {
    if (isEditing) {
      document.querySelector('#bannerInput').click();
    }
  };

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewBanner(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const uploadBanner = async () => {
    const fileInput = document.querySelector('#bannerInput');
    const file = fileInput.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('banner', file);
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/events/${eventId}/banner`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true
        });
        setEditableEvent((prev) => ({ ...prev, thumbnail: response.data.thumbnail }));
        toast.success('Banner updated successfully');
      } catch (error) {
        toast.error('Error updating banner');
      }
    }
  };
  const getStats = (users, selectedDay) => {
    const filteredUsers = users.filter((user) => {
      if (!selectedDay) return true; // Include all users if no day is selected
    
      const formattedSelectedDay = dayjs(selectedDay).utc().format('YYYY-MM-DD'); // Format selected day as YYYY-MM-DD
      const userDates = Object.keys(user.date || {}); // Get all keys (dates) from user's date object
    
      // Check if the formatted selected day is in the user's dates
      return userDates.includes(formattedSelectedDay);
    });
    const totalUsers = filteredUsers.length;
    const totalWalkIn = filteredUsers.filter((user) => user.Type === 'Walk-In').length;
    const totalPreReg = filteredUsers.filter((user) => user.Type === 'Pre-Registration').length;
    const walkInCheckIn = filteredUsers.filter((user) => user.Type === 'Walk-In' && user.checkedIn).length;
    const preRegCheckIn = filteredUsers.filter((user) => user.Type === 'Pre-Registration' && user.checkedIn).length;
    const totalCheckedInUsers = filteredUsers.filter((user) => user.checkedIn).length;
  
    return {
      totalUsers,
      totalWalkIn,
      totalPreReg,
      walkInCheckIn,
      preRegCheckIn,
      totalCheckedInUsers,
    };
  };
  const renderUserRounds = (userDate, eventRounds) => {
    console.log('userDate:', userDate);
    console.log('eventRounds:', eventRounds);
  
    if (!userDate || Object.keys(userDate).length === 0) {
      return <p>ไม่มีรอบสำหรับวันที่เลือก</p>;
    }
  
    return Object.entries(userDate).map(([date, rounds], index) => {
      console.log(`Processing date: ${date}`);
      console.log('Rounds for this date:', rounds);
  
      // Fix: Treat the date as local to avoid shifting
      const normalizedDate = dayjs(date).startOf('day').format('YYYY-MM-DD');
      console.log('Normalized date for lookup:', normalizedDate);
  
      const roundDetails = rounds.map((round) => {
        const roundInfo = eventRounds?.[normalizedDate]?.[round - 1];
        console.log(`Looking up roundInfo: eventRounds[${normalizedDate}][${round - 1}]`);
        console.log('Available rounds for this date in eventRounds:', eventRounds?.[normalizedDate]);
        console.log('Mapped roundInfo:', roundInfo);
  
        if (roundInfo?.start && roundInfo?.end) {
          return `รอบ ${round}: ${formatTime(roundInfo.start)} - ${formatTime(roundInfo.end)}`;
        }
        return `รอบ ${round}: ทั้งวัน`;
      });
  
      console.log('Final roundDetails for date:', date, roundDetails);
  
      return (
        <div key={index} style={{ marginBottom: '10px' }}>
          <p style={{ fontWeight: 'bold' }}>{dayjs(date).locale('th').format('D MMMM YYYY')}</p>
          {roundDetails.length > 0 ? (
            roundDetails.map((detail, i) => {
              console.log('Rendering round detail:', detail);
              return <p key={i}>{detail}</p>;
            })
          ) : (
            <p>ทั้งวัน</p>
          )}
        </div>
      );
    });
  };
  const getLabelCheckInStats = (users, selectedDay) => {
    const formattedDay = selectedDay
      ? dayjs(selectedDay).utc().format('YYYY-MM-DD')
      : null;
  
    const filteredUsers = users.filter((user) => {
      if (!formattedDay) return true; // Include all users if no day is selected
      return user.date?.[formattedDay]; // Include only users who have entries for the selected day
    });
  
    const labelStats = {};
    filteredUsers.forEach((user) => {
      if (!labelStats[user.label]) {
        labelStats[user.label] = { total: 0, checkIn: 0 };
      }
      labelStats[user.label].total += 1;
      if (user.checkedIn) {
        labelStats[user.label].checkIn += 1;
      }
    });
  
    return labelStats;
  };
  const getRoundCheckInStats = (users, selectedDay, rounds) => {
    if (!selectedDay || !rounds) return [];
  
    const formattedDay = dayjs(selectedDay).utc().format('YYYY-MM-DD');
    const selectedRounds = rounds[formattedDay] || [];
    const roundStats = selectedRounds.map(() => ({ total: 0, checkIn: 0 }));
  
    users.forEach((user) => {
      const userRounds = user.date?.[formattedDay] || [];
      userRounds.forEach((roundIndex) => {
        if (roundStats[roundIndex - 1]) {
          roundStats[roundIndex - 1].total += 1;
          if (user.checkedIn) {
            roundStats[roundIndex - 1].checkIn += 1;
          }
        }
      });
    });
  
    return roundStats;
  };
  const getCheckInTimesData = (users, selectedDay) => {
    const checkInCounts = {};
  
    users
      .filter(user => user.CheckInTime) // Only include users who have checked in
      .filter(user => {
        if (!selectedDay) return true; // No filter applied
        const checkInDate = dayjs(user.CheckInTime).utc();
        return checkInDate.isSame(dayjs(selectedDay).utc(), 'day');
      })
      .forEach(user => {
        const checkInTime = dayjs(user.CheckInTime).tz('Asia/Bangkok').format('HH:mm');
        if (checkInCounts[checkInTime]) {
          checkInCounts[checkInTime] += 1;
        } else {
          checkInCounts[checkInTime] = 1;
        }
      });
  
    // Convert the object to an array suitable for Recharts
    const checkInDataArray = Object.keys(checkInCounts).map(time => ({
      time,
      count: checkInCounts[time],
    }));
  
    // Sort the array by time
    checkInDataArray.sort((a, b) => dayjs(a.time, 'HH:mm').isBefore(dayjs(b.time, 'HH:mm')) ? -1 : 1);
  
    return checkInDataArray;
  };
  const stats = getStats(registeredUsers);
  const checkInData = getCheckInTimesData(registeredUsers);
  console.log("checkInData",checkInData)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableEvent((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateRangeChange = (range) => {
    setEditableEvent((prev) => ({
      ...prev,
      startDate: range[0].toISOString(),
      endDate: range[1].toISOString(),
    }));
    setDateRange(range);
  };

  const handleSeatChange = (userId, newSeat) => {
    updateUserData(userId, 'seatNumber', newSeat);
  };

  const handleNameChange = (userId, newValue, field) => {
    updateUserData(userId, field, newValue);
  };

  const updateUserData = (userId, field, value) => {
    setRegisteredUsers((prevUsers) =>
      prevUsers.map((user) => (user._id === userId ? { ...user, [field]: value } : user))
    );
    setUpdatedUsers((prevUpdatedUsers) => {
      const existingUser = prevUpdatedUsers.find((user) => user._id === userId);
      if (existingUser) {
        return prevUpdatedUsers.map((user) => (user._id === userId ? { ...user, [field]: value } : user));
      }
      return [...prevUpdatedUsers, { _id: userId, [field]: value }];
    });
  };

  const handleSendEmail = async () => {
    try {
      let usersToSend = [...registeredUsers]; // Start with all users
      console.log("Initial registeredUsers:", registeredUsers);
      console.log("sendToAll:", sendToAll);
      console.log("selectedLabel:", selectedLabel);
      console.log("selectedUsers:", selectedUsers);
      console.log("selectedDay:", selectedDay);
      console.log("selectedRound:", selectedRound);
  
      // If sendToAll is true, no further filtering is needed
      if (!sendToAll) {
        // If a label is selected, filter by label
        if (selectedLabel) {
          usersToSend = usersToSend.filter(user => user.label === selectedLabel);
          console.log(`Filtered users by label "${selectedLabel}":`, usersToSend);
        }
  
        // If specific users are selected, filter by those users
        if (selectedUsers.length) {
          usersToSend = usersToSend.filter(user => selectedUsers.includes(user._id));
          console.log("Filtered users by selected users:", usersToSend);
        }
  
        // If a specific day is selected, filter by that day
        if (selectedDay) {
          const formattedDay = dayjs(selectedDay).utc().format('YYYY-MM-DD');
          console.log("Formatted selectedDay for filtering:", formattedDay);
  
          usersToSend = usersToSend.filter(user => {
            const userDates = Object.keys(user.date || {}); // Extract dates from user's date object
            return userDates.includes(formattedDay); // Check if the selected day exists in the user's dates
          });
  
          console.log(`Filtered users by selectedDay "${formattedDay}":`, usersToSend);
        }
  
        // If a specific round is selected, filter by that round
        if (selectedRound && selectedDay) {
          const formattedDay = dayjs(selectedDay).utc().format('YYYY-MM-DD');
          console.log("Formatted day for round filtering:", formattedDay);
  
          usersToSend = usersToSend.filter(user =>
            user.date?.[formattedDay]?.includes(Number(selectedRound))
          );
  
          console.log(`Filtered users by selectedRound "${selectedRound}":`, usersToSend);
        }
      }
  
      // If no users are selected after filtering, show an error and exit
      if (!usersToSend.length) {
        console.error("No users selected for sending email. Final usersToSend:", usersToSend);
        toast.error('No users selected for sending email');
        return;
      }
  
      console.log("Final usersToSend:", usersToSend);
  
      // Send email to each selected user
      const promises = usersToSend.map(async (user) => {
        console.log(`Sending email to user: ${user.name}, ID: ${user._id}`);
        await axios.post(
          `${process.env.REACT_APP_API_URL}/users/${user._id}/send-email`,
          { emailType },
          { withCredentials: true }
        );
      });
  
      await Promise.all(promises);
      toast.success(`Email (${emailType}) sent to selected users`);
      setShowEmailModal(false);
    } catch (error) {
      console.error("Error during email sending process:", error);
      toast.error('Error sending emails');
    }
  };
  const groupColors = [
    '#ffe6e6', '#e6f7ff', '#e6ffe6', '#fffbe6', '#f9e6ff',
    '#ffe6fa', '#e6fff7', '#fff5e6', '#e6e6ff', '#e6fffa'
  ];

  const assignGroupColor = (duplicateGroup) => {
    if (!duplicateGroup) return '';  // No color if not part of a duplicate group
    const groupIndex = duplicateGroup % groupColors.length;
    return groupColors[groupIndex];
  };
  const handleConfirmUserEdit = async () => {
    try {
      const promises = updatedUsers.map(async (user) => {
        await axios.put(`${process.env.REACT_APP_API_URL}/users/${user._id}/edit`, user, { withCredentials: true });
      });

      await Promise.all(promises);
      toast.success('Users updated successfully');
      setUpdatedUsers([]);
      setIsTableEditing(false);
      await fetchRegisteredUsers();
    } catch (error) {
      toast.error('Error updating users');
    }
  };

  const handleApproveUser = async (userId) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/users/${userId}/approve`, { eventId }, { withCredentials: true });
      toast.success('User approved!');
      setRegisteredUsers((prev) =>
        prev.map((user) => (user._id === userId ? { ...user, status: 'approved' } : user))
      );
    } catch (error) {
      toast.error('Error approving user');
    }
  };

  const handleDeclineUser = async (userId) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/users/${userId}/decline`, { eventId }, { withCredentials: true });
      toast.success('User declined');
      setRegisteredUsers((prev) =>
        prev.map((user) => (user._id === userId ? { ...user, status: 'declined' } : user))
      );
    } catch (error) {
      toast.error('Error declining user');
    }
  };

  const handleDeleteSelectedUsers = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/users/delete`, { userIds: selectedUsers }, { withCredentials: true });
      toast.success('Selected users deleted successfully');
      setRegisteredUsers((prev) => prev.filter((user) => !selectedUsers.includes(user._id)));
      setSelectedUsers([]);
    } catch (error) {
      toast.error('Error deleting users');
    }
  };
  const handleExport = () => {
    const exportData = filteredUsers.map(user => ({
      name: user.name,
      lastName: user.lastName,
      seat:user.seatNumber,
      phone: user.phone,
      email: user.email,
      invitationAccepted: user.invitationAccepted === true ? 'Accepted' : user.invitationAccepted === false ? 'Declined' : 'Pending',
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
    XLSX.writeFile(workbook, `Event_Users_${eventId}.xlsx`);
  };

  const handleRowSelectionChange = (selectedRowKeys) => {
    setSelectedUsers(selectedRowKeys);
  };

  const handleTableEditClick = () => {
    setIsTableEditing((prev) => !prev);
  };
  const fetchRegisteredUsers = async () => {
    console.log("eventId",eventId)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/events/${eventId}/registered-users`, { withCredentials: true });
      setRegisteredUsers(response.data);
    } catch (error) {
      toast.error('Error fetching registered users!');
    }
  };
  const handleAddVIP = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/vip`, {
        name: vipGuest.name,
        lastName: vipGuest.lastName,
        email: vipGuest.email,
        phone: vipGuest.phone,
        label: vipGuest.label,
        company: vipGuest.company,
        position: vipGuest.position,
        sender: vipGuest.sender,
        date: vipGuest.date, // Selected dates
        round: vipGuest.round, // Selected round indices per date
        eventId,
      }, { withCredentials: true });
  
      if (response.status === 201) {
        toast.success('VIP guest added successfully');
        setShowVIPModal(false);
        setVipGuest({ name: '', lastName: '', email: '', phone: '', label: '', date: [], round: {} });
        await fetchRegisteredUsers();
      }
    } catch (error) {
      console.error('Error adding VIP guest:', error);
      toast.error('Error adding VIP guest');
    }
  };
  const handleCheckIn = async (userId) => {
    try {
      // Update check-in status in the backend
      // await axios.post(`${process.env.REACT_APP_API_URL}/users/${userId}/check-in`, {}, { withCredentials: true });
      // toast.success('User successfully checked in!');
  
      // Update the UI to reflect the check-in status
      setRegisteredUsers((prevUsers) =>
        prevUsers.map((user) => (user._id === userId ? { ...user, checkedIn: true, CheckInTime: new Date() } : user))
      );
  
      // Fetch user details for printing
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}/print-data`, { withCredentials: true });
      const { name, lastName, seatNumber, qrCode } = response.data;
  
      // Initialize Zebra Browser Print
      const browserPrint = new ZebraBrowserPrintWrapper();
      const defaultPrinter = await browserPrint.getDefaultPrinter();
  
      if (!defaultPrinter) {
        toast.error('No printer found. Please connect your Zebra printer.');
        return;
      }
  
      browserPrint.setPrinter(defaultPrinter);
  
      // Generate ZPL (Zebra Programming Language) for printing
      const zpl = `
        ^XA
        ^PW400
        ^LL160
        ^LH0,5
        ^LT0
        ^CW1,E:ANGSANA.TTF
        ^CI28
        ^SEE:CP874.DAT

        ^FO20,10^A1N,60,50^FDชื่อ ${name} ${lastName}^FS
        ^FO20,60^A1N,60,50^FDที่นั่ง ${seatNumber}^FS

        ^XZ
      `;
  
      // Send the ZPL to the printer
      await browserPrint.print(zpl);
      toast.success('Print job sent successfully!');
    } catch (error) {
      console.error('Error during check-in or printing:', error);
      toast.error('Error checking in the user or sending the print job.');
    }
  };
  // const handleCheckIn = async (userId) => {
  //   try {
  //     // await axios.post(`${process.env.REACT_APP_API_URL}/users/${userId}/check-in`, {}, { withCredentials: true });
  //     // toast.success('User successfully checked in!');
      
  //     // Update the UI to reflect the check-in status
  //     setRegisteredUsers((prevUsers) =>
  //       prevUsers.map((user) => (user._id === userId ? { ...user, checkedIn: true, CheckInTime: new Date() } : user))
  //     );
      
  //     // Optionally, close the modal
  //     setShowUserInfoModal(false);
  //     fetchRegisteredUsers();
  //     await axios.post(`${process.env.REACT_APP_API_URL}/users/${userId}/print`, {}, { withCredentials: true });
  //     toast.success('Print job sent successfully!');
  //   } catch (error) {
  //     toast.error('Error checking in the user');
  //   }
  // };
  const handleSearch = (value) => {
    setSearchTerm(value.toLowerCase());
  };
  const handleInvitationStatusChange = (value) => {
    setInvitationStatusFilter(value);
  };
  const handleCheckInSearch = (value) => {
    setCheckInSearchTerm(value.toLowerCase());
  };
  const handleCheckInStatusChange = (value) => {
    setCheckInStatusFilter(value);
  };
  useEffect(() => {
    setSelectedRound(null); // Reset round when day changes
  }, [selectedDay]);
  const filteredUsers = registeredUsers.filter((user) => {
    const fullName = `${user.name.toLowerCase()} ${user.lastName.toLowerCase()}`;
    const matchesSearchTerm =
      fullName.includes(searchTerm) ||
      user.seatNumber?.toLowerCase().includes(searchTerm) ||
      user.phone?.toLowerCase().includes(searchTerm);
  
    const matchesInvitationStatus =
      invitationStatusFilter === null ||
      (invitationStatusFilter === true && user.invitationAccepted === true) ||
      (invitationStatusFilter === false && user.invitationAccepted === false) ||
      (invitationStatusFilter === 'pending' && user.invitationAccepted === null);
  
    const matchesCheckInStatus =
      checkInStatusFilter === 'all' ||
      (checkInStatusFilter === 'checkedIn' && user.checkedIn === true) ||
      (checkInStatusFilter === 'notCheckedIn' && user.checkedIn === false);
  
    const matchesDay = !selectedDay || (
      Object.keys(user.date).some(dateKey => {
        // Normalize both `selectedDay` and `dateKey` to the same format and compare only the date
        const isMatch = dayjs(dateKey).isSame(dayjs(selectedDay).utc().startOf('day'), 'day');
        console.log(`User ${user.name}: dateKey=${dateKey}, selectedDay=${selectedDay}, isMatch=${isMatch}`);
        return isMatch;
      })
    );
  
    const formattedDay = dayjs(selectedDay).utc().format('YYYY-MM-DD'); // Use UTC to avoid timezone shifts
    const roundArray = user.date ? user.date[formattedDay] : undefined;

    // Additional Debug Logs
    

    const isArray = Array.isArray(roundArray);
    const parsedSelectedRound = Number(selectedRound); // Ensure selectedRound is a number
    const includesRound = isArray && roundArray.includes(parsedSelectedRound);

    

    const matchesRound =
      !selectedRound || (isArray && includesRound);

    
      
    const matchesLabel = !selectedFilterLabel || user.label === selectedFilterLabel;
  
    return (
      matchesSearchTerm &&
      matchesInvitationStatus &&
      matchesCheckInStatus &&
      matchesDay &&
      matchesRound &&
      matchesLabel
    );
  });
  

  const totalCustomers = filteredUsers.length;
  const filteredCheckInUsers = registeredUsers.filter((user) => 
    (user.checkedIn && user.CheckInTime) && // Ensure the user has checked in and has a CheckInTime
    (
      user.name.toLowerCase().includes(checkInSearchTerm) || 
      user.lastName.toLowerCase().includes(checkInSearchTerm)
    )
  );
  const sortedUsers = [...registeredUsers].sort((a, b) => {
    if (a.isDuplicate && !b.isDuplicate) return -1;
    if (!a.isDuplicate && b.isDuplicate) return 1;

    // Sort by duplicateGroup, then by name if in the same group
    if (a.duplicateGroup && b.duplicateGroup) {
      return a.duplicateGroup - b.duplicateGroup || a.name.localeCompare(b.name, 'th');
    }

    return a.name.localeCompare(b.name, 'th');
  });
  
  const handleImportSelection = (type) => {
    setImportType(type);
    setShowImportModal(false);
  
    // Trigger the file input click after the user selects the import type
    document.querySelector('#fileInput').click();
  };
  const handleUpload = async (file) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
  
      
      if (importType === 'importUser') {
        await importUsers(parsedData);
      } else if (importType === 'importSeat') {
        await importSeats(parsedData);
      }
    } catch (error) {
      toast.error('Error importing users');
    }
  };
  
  // Import users and highlight duplicates
  const importUsers = async (users) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/import`, { users, eventId }, { withCredentials: true });
      console.log("response",response.data)
      if (response.data.duplicates && response.data.duplicates.length > 0) {
        setDuplicateUsers(response.data.duplicates); // Highlight duplicates
        toast.warning('Some users have duplicate names, last names or seat number.');
      } else {
        toast.success('Users imported successfully');
      }
  
      await fetchRegisteredUsers(); // Refresh the user data
    } catch (error) {
      toast.error('Error importing users');
    }
  };
  
  // Import seats for existing users
  const importSeats = async (seatsData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/import-seats`, { seatsData, eventId }, { withCredentials: true });
      toast.success('Seats imported successfully');
      await fetchRegisteredUsers(); // Refresh the user data
    } catch (error) {
      toast.error('Error importing seats');
    }
  };
  const handleRowClick = (user) => {
    setSelectedUser(user);
    setShowUserInfoModal(true);
    setScannedCheckIn(false);
  };
  
  const handleExportCheckInData = () => {
    
    const exportData = registeredUsers.map(user => ({
      
      name: user.name,
      lastName: user.lastName,
      seatNumber:user.seatNumber,
      company:user.company,
      position:user.position,
      CheckInTime: user.CheckInTime ? dayjs(user.CheckInTime).tz('Asia/Bangkok').format('MMMM Do YYYY, HH:mm:ss'): 'Not Checked In',
      Type:user.Type,
      sender:user.sender
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'CheckInData');
    XLSX.writeFile(workbook, `CheckInData_Event_${eventId}.xlsx`);
  };
  const handleGenerateQRCodeForUser = async (userId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/${userId}/generate-qr-code`, {}, { withCredentials: true });
  
      if (response.status === 200) {
        toast.success('QR Code generated successfully!');
        // Refresh the users data after QR code generation
        fetchRegisteredUsers();
      }
    } catch (error) {
      console.error('Error generating QR Code for user:', error);
      toast.error('Error generating QR Code for user');
    }
  };
  const checkInColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, user) => `${user.name} ${user.lastName}`,
    },
    {
      title: 'Check-In Time',
      dataIndex: 'CheckInTime',  // Use the exact key from your backend model
      key: 'CheckInTime',
      render: (CheckInTime) => (CheckInTime ? dayjs(CheckInTime).tz('Asia/Bangkok').format('MMMM Do YYYY, HH:mm:ss'): 'Not Checked In'),
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      render: (_, user) => `${user.Type}`,
    },
  ];
  useEffect(() => {
    console.log('duplicateUsers', duplicateUsers);
  }, [showImportModal]);
  const formatDate_date = (dateStr) => {
    const buddhistYear = dayjs.utc(dateStr).year() + 543; // Add 543 to the Gregorian year
    return dayjs.utc(dateStr).locale('th').format(`D MMMM ${buddhistYear}`); // Replace year with Buddhist year
  };
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toLocaleDateString('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };
  
  const formatTime = (dateStr) => {
    console.log("Raw dateStr:", dateStr);
    if (!dateStr) return 'Invalid Time';
    return dayjs.utc(dateStr).format('HH:mm'); // Interpret as UTC and format without conversion
  };
  const renderRounds = (rounds) => {
    if (!rounds || Object.keys(rounds).length === 0) {
      return <p>No rounds available.</p>;
    }
  
    return Object.entries(rounds).map(([date, roundArray], index) => (
      <div key={index} style={{ marginBottom: '10px' }}>
        <p style={{ fontWeight: 'bold' }}>{formatDate_date(date)}</p>
        {roundArray.length > 0 ? (
          roundArray.map((round, roundIndex) => {
            if (round.start && round.end) {
              return (
                <p key={roundIndex}>
                  รอบ {roundIndex + 1}: {formatTime(round.start)} - {formatTime(round.end)}
                </p>
              );
            } else {
              return <p key={roundIndex}>ทั้งวัน</p>;
            }
          })
        ) : (
          <p>ทั้งวัน</p> // If no rounds for this date, show "ทั้งวัน"
        )}
      </div>
    ));
  };
  const handleGenerateQRCode = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/events/${eventId}/generate-qr-code`, {}, { withCredentials: true });
  
      // Show a toast or notification on success
      toast.success('QR Code generated successfully!');
    } catch (error) {
      console.error('Error generating QR Code:', error);
      toast.error('Error generating QR Code');
    }
  };
  const columns = [
    {
      title: 'Index',
      dataIndex: 'index',
      key: 'index',
      render: (index) => <span>{index}</span>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, user) => {
        const isNameDuplicate = user.duplicateFields?.includes('name_lastName');
        return isTableEditing ? (
          <Input
            value={user.name || ''}
            onChange={(e) => handleNameChange(user._id, e.target.value, 'name')}
            style={{ backgroundColor: isNameDuplicate ? '#ffe6e6' : 'inherit' }} // Highlight duplicate in red
          />
        ) : (
          <span
            onClick={() => handleRowClick(user)}
            style={{
              cursor: 'pointer',
              color: isNameDuplicate ? 'red' : '#1890ff', // Highlight text in red if duplicate
            }}
          >
            {`${user.name} ${user.lastName}`}
          </span>
        );
      },
    },
    {
      title: 'Rounds',
      dataIndex: 'date',
      key: 'rounds',
      render: (dateMap) => {
        if (!dateMap || Object.keys(dateMap).length === 0) {
          return 'ทั้งวัน';
        }
        return (
          <div>
            {Object.entries(dateMap).map(([date, rounds]) => (
              <div key={date}>
                {dayjs(date).locale('th').format('D MMMM')} :{' '}
                {rounds.length > 0 ? rounds.join(', ') : 'ทั้งวัน'}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Company Name',
      dataIndex: 'company',
      key: 'company',
      render: (_, user) => isTableEditing ? (
        <Input
          value={user.company || ''}
          onChange={(e) => handleNameChange(user._id, e.target.value, 'company')}
        />
      ) : user.company || 'N/A',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      render: (_, user) => isTableEditing ? (
        <Input
          value={user.position || ''}
          onChange={(e) => handleNameChange(user._id, e.target.value, 'position')}
        />
      ) : user.position || 'N/A',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      render: (_, user) => isTableEditing ? (
        <Input
          value={user.phone || ''}
          onChange={(e) => handleNameChange(user._id, e.target.value, 'phone')}
        />
      ) : user.phone,
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      render: (_, user) => isTableEditing ? (
        <Input
          value={user.label || ''}
          onChange={(e) => handleNameChange(user._id, e.target.value, 'label')}
        />
      ) : user.label,
    },
    {
      title: 'Seat Number',
      dataIndex: 'seatNumber',
      key: 'seatNumber',
      render: (_, user) => {
        const isSeatDuplicate = user.duplicateFields?.includes('seatNumber');
        return isTableEditing ? (
          <Input
            value={user.seatNumber || ''}
            onChange={(e) => handleSeatChange(user._id, e.target.value)}
            style={{ backgroundColor: isSeatDuplicate ? '#ffe6e6' : 'inherit' }} // Highlight duplicate seat number
          />
        ) : (
          <span
            style={{ color: isSeatDuplicate ? 'red' : 'inherit' }} // Highlight seat number in red if duplicate
          >
            {user.seatNumber}
          </span>
        );
      },
    },
    {
      title: 'Invitation Email Sent',
      dataIndex: 'invitationEmailSent',
      key: 'invitationEmailSent',
      render: (emailSent) => (
        emailSent ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />
      ),
    },
    {
      title: 'Invitation Accepted',
      dataIndex: 'invitationAccepted',
      key: 'invitationAccepted',
      render: (invitationAccepted) => (
        invitationAccepted === true ? <CheckCircleOutlined style={{ color: 'green' }} /> 
        : invitationAccepted === false ? <CloseCircleOutlined style={{ color: 'red' }} /> 
        : <span>Pending</span>
      ),
    },
    {
      title: 'Sender',
      dataIndex: 'sender',
      key: 'sender',
      render: (_, user) => isTableEditing ? (
        <Input
          value={user.sender || ''}
          onChange={(e) => handleNameChange(user._id, e.target.value, 'sender')}
        />
      ) : user.sender || 'N/A',
    },
    // {
    //   title: 'QR Code Email Sent',
    //   dataIndex: 'qrCodeEmailSent',
    //   key: 'qrCodeEmailSent',
    //   render: (emailSent) => (
    //     emailSent ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />
    //   ),
    // },
    {
      title: 'Checked In',
      dataIndex: 'checkedIn',
      key: 'checkedIn',
      render: (checkedIn) =>
        checkedIn ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, user) => {
        return (
          <>
            {user.qrCode ? (
              <Tag color="green">QR Code Generated</Tag>
            ) : (
              <Button
                icon={<QrcodeOutlined />}
                type="primary"
                onClick={() => handleGenerateQRCodeForUser(user._id)}
              >
                Generate QR Code
              </Button>
            )}
          </>
        );
      },
    },
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   render: (_, user) => (
    //     <>
    //       {user.status === 'approved' ? (
    //         <Tag color="green">Approved</Tag>
    //       ) : user.status === 'declined' ? (
    //         <Tag color="red">Declined</Tag>
    //       ) : (
    //         userRole === 'admin' && (
    //           <>
    //             <Button
    //               type="primary"
    //               onClick={() => handleApproveUser(user._id)}
    //               disabled={user.status === 'approved'}
    //               style={{ marginRight: '5px' }}
    //             >
    //               Approve
    //             </Button>
    //             <Button
    //               type="danger"
    //               onClick={() => handleDeclineUser(user._id)}
    //               disabled={user.status === 'declined'}
    //             >
    //               Decline
    //             </Button>
    //           </>
    //         )
    //       )}
    //     </>
    //   ),
    // },
  ];

  const rowSelection = {
    selectedRowKeys: selectedUsers,
    onChange: handleRowSelectionChange,
  };

  return (
    <div className="event-page">
      {userRole === 'admin' && (
        <div className="qr-code-button-container">
          <Tooltip title="Generate QR Code">
            <Button 
              icon={<QrcodeOutlined />} 
              onClick={handleGenerateQRCode}
            >
              Generate QR Code
            </Button>
          </Tooltip>
        </div>
      )}
      <div className="banner" style={{ backgroundImage: `url(${previewBanner || process.env.REACT_APP_STORAGE + event?.thumbnail})` }} onClick={handleBannerClick}>
        {userRole === 'admin' && (
          <div className="banner-actions">
            {/* <Tooltip title="Edit Event">
              <Button icon={<EditOutlined />} onClick={(e) => { e.stopPropagation(); handleEditClick(e); }} />
            </Tooltip> */}
            <Tooltip title="Delete Event">
              <Button icon={<DeleteOutlined />} onClick={(e) => { e.stopPropagation(); setShowDeleteConfirm(true); }} danger />
            </Tooltip>
            {isEditing && (
              <>
                <Tooltip title="Finish Editing">
                  <Button icon={<CheckOutlined />} onClick={(e) => { e.stopPropagation(); handleEditClick(e); }} style={{ marginLeft: '10px' }} />
                </Tooltip>
                <Tooltip title="Cancel Editing">
                  <Button icon={<CloseCircleOutlined />} onClick={(e) => { e.stopPropagation(); handleCancelEdit(e); }} style={{ marginLeft: '10px' }} />
                </Tooltip>
              </>
            )}
          </div>
        )}
        <input type="file" style={{ display: 'none' }} id="bannerInput" onChange={handleBannerChange} />
      </div>

      <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        {/* Left Column: Event Details */}
        <Col xs={24} md={16}>
          <h1>{isEditing ? <Input value={editableEvent.name} name="name" onChange={handleInputChange} /> : event?.name}</h1>
          <p>{isEditing ? <Input value={editableEvent.time} name="time" onChange={handleInputChange} /> : `Time: ${event?.time}`}</p>
          {isEditing ? (
            <div>
              <RangePicker value={dateRange} onChange={handleDateRangeChange} style={{ marginBottom: '10px' }} />
              <Input value={editableEvent.location} name="location" placeholder="Location" onChange={handleInputChange} />
            </div>
          ) : (
            <>
              <p>
                Date: {event?.date
                  ? `${formatDate_date(event.date[0])} - ${formatDate_date(event.date[1])}`
                  : 'N/A'}
              </p>
              <p>Location: {event?.location}</p>
            </>
          )}
        </Col>

        {/* Right Column: Rounds */}
        <Col xs={24} md={8}>
          <div className="rounds-section">
            {renderRounds(event?.rounds)}
          </div>
        </Col>
      </Row>
    
    <Tabs
        defaultActiveKey="registered"
        items={[
          {
            label: 'Registered Users',
            key: 'registered',
            children: (
              <>
              <div style={{ marginBottom: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                  <Input.Search
                    placeholder="Search by name, seat number, or phone"
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: '300px' }}
                  />
                  <Select
                    placeholder="Filter by Invitation Status"
                    style={{ width: 200, marginRight: '10px', flexGrow: 1 }}
                    allowClear
                    onChange={handleInvitationStatusChange}
                    defaultValue={null}
                  >
                    <Option value={null}>All</Option>
                    <Option value={true}>Accepted</Option>
                    <Option value={false}>Declined</Option>
                    <Select.Option value="pending">Pending</Select.Option>
                  </Select>
                  <Select
                    placeholder="Filter by Check-In Status"
                    style={{ width: 200, marginLeft: '10px', flexGrow: 1 }}
                    onChange={handleCheckInStatusChange}
                    defaultValue="all"  // Set default value to "all"
                  >
                    <Option value="all">All</Option>
                    <Option value="checkedIn">Checked In</Option>
                    <Option value="notCheckedIn">Not Checked In</Option>
                  </Select>
                  <Select
                    placeholder="Filter by Day"
                    style={{ width: 200, marginRight: '10px' }}
                    onChange={(value) => setSelectedDay(value)}
                    allowClear
                  >
                    {event?.allDates.map((date) => (
                      <Option key={date.toISOString()} value={date.toISOString()}>
                        {formatDate_date(date)}
                      </Option>
                    ))}
                  </Select>

                  <Select
                    placeholder="Filter by Round"
                    style={{ width: 200, marginRight: '10px' }}
                    onChange={(value) => setSelectedRound(value)}
                    allowClear
                  >
                    {selectedDay &&
                      event?.rounds &&
                      event.rounds[dayjs(selectedDay).utc().format('YYYY-MM-DD')]?.map((round, index) => (
                        <Option key={index + 1} value={index + 1}>
                          {round.start && round.end
                            ? `${formatTime(round.start)} - ${formatTime(round.end)}`
                            : 'ทั้งวัน'}
                        </Option>
                      ))}
                  </Select>

                  <Select
                    placeholder="Filter by Label"
                    style={{ width: 200, marginRight: '10px' }}
                    onChange={(value) => setSelectedFilterLabel(value)}
                    allowClear
                  >
                    {labelOptions.map((label) => (
                      <Option key={label} value={label}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                  
                  {userRole === 'admin' && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Tooltip title="Edit Table">
                        <Button
                          icon={<EditOutlined />}
                          onClick={handleTableEditClick}
                        />
                      </Tooltip>
                      <Button
                        type="primary"
                        style={{ marginLeft: '10px' }}
                        onClick={() => setShowVIPModal(true)}
                        icon={<PlusOutlined />}
                      >
                        Add VIP
                      </Button>
                      {/* <Upload
                        accept=".xlsx, .xls"
                        showUploadList={false}
                        beforeUpload={handleUpload}
                      > */}
                        <Button
                          icon={<UploadOutlined />}
                          style={{ marginLeft: '10px' }}
                          onClick={() => setShowImportModal(true)}
                        >
                          Import Excel
                        </Button>
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: 'none' }}
                          accept=".xlsx, .xls"
                          onChange={(e) => handleUpload(e.target.files[0])} // Handle file upload after selecting file
                        />
                      {/* </Upload> */}
                      <Button
                        type="primary"
                        style={{ marginLeft: '10px' }}
                        onClick={handleExport}
                      >
                        Export Excel
                      </Button>
                      <Button
                        type="primary"
                        style={{ marginLeft: '10px' }}
                        onClick={() => setShowEmailModal(true)}
                      >
                        Send Email
                      </Button>
                      {isTableEditing && (
                        <>
                          <Button
                            type="danger"
                            onClick={() => setIsTableEditing(false)}
                            style={{ marginLeft: '10px' }}
                          >
                            Cancel Edit
                          </Button>
                          <Button
                            type="primary"
                            onClick={handleConfirmUserEdit}
                            style={{ marginLeft: '10px' }}
                          >
                            Confirm Edit
                          </Button>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div style={{ marginTop: '5px', fontSize: '15px' }}>
                  Total Customers: {filteredUsers.length}
                </div>
              </div>
                
                <Table
                  columns={columns}
                  dataSource={filteredUsers}
                  rowKey="_id"
                  pagination={false}
                  rowSelection={isTableEditing ? rowSelection : null}
                  rowClassName={(record) => {
                    // Highlight duplicates using isDuplicate field
                    return record.isDuplicate ? 'duplicate-row' : '';
                  }}
                />
                {isTableEditing && selectedUsers.length > 0 && (
                  <Button type="danger" style={{ marginTop: '10px' }} onClick={handleDeleteSelectedUsers}>
                    Delete Selected Users
                  </Button>
                )}
              </>
            ),
          },
          {
            label: 'Check-In Data',
            key: 'checkin',
            children: (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                  <Input.Search
                    placeholder="Search by name"
                    onChange={(e) => handleCheckInSearch(e.target.value)}
                    style={{ width: '300px' }}
                  />
                  {userRole === 'admin' && (
                    <Button
                      type="primary"
                      onClick={handleExportCheckInData}
                      style={{ marginLeft: '10px' }}
                    >
                      Export Check-In Data
                    </Button>
                  )}
                </div>
                <Table
                  columns={checkInColumns}
                  dataSource={filteredCheckInUsers}
                  rowKey="_id"
                  pagination={false}
                />
              </>
            ),
          },
          {
            label: 'Graph',
            key: 'graph',
            children: (
              <>
                {/* Calculate labelStats and roundStats */}
                {(() => {
                  const labelStats = getLabelCheckInStats(registeredUsers, graphSelectedDay);
                  const roundStats = getRoundCheckInStats(registeredUsers, graphSelectedDay, event?.rounds);
                  return (
                    <>
                      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Select
                          placeholder="Filter by Day"
                          style={{ width: 200, marginRight: '10px' }}
                          onChange={(value) => setGraphSelectedDay(value)}
                          allowClear
                        >
                          {event?.allDates.map((date) => (
                            <Option key={date.toISOString()} value={date.toISOString()}>
                              {formatDate_date(date)}
                            </Option>
                          ))}
                        </Select>
                      </div>
          
                      <Row gutter={16} style={{ marginBottom: '20px' }}>
                        <Col span={6}>
                          <Card title="Total Registered Users" bordered={false}>
                            {getStats(registeredUsers, graphSelectedDay).totalUsers}
                          </Card>
                        </Col>
                        <Col span={6}>
                          <Card title="Total Walk-In Users" bordered={false}>
                            {getStats(registeredUsers, graphSelectedDay).totalWalkIn} (Check-In: {getStats(registeredUsers, graphSelectedDay).walkInCheckIn}/{getStats(registeredUsers, graphSelectedDay).totalWalkIn})
                          </Card>
                        </Col>
                        <Col span={6}>
                          <Card title="Total Pre-Registration Users" bordered={false}>
                            {getStats(registeredUsers, graphSelectedDay).totalPreReg} (Check-In: {getStats(registeredUsers, graphSelectedDay).preRegCheckIn}/{getStats(registeredUsers, graphSelectedDay).totalPreReg})
                          </Card>
                        </Col>
                        <Col span={6}>
                          <Card title="Total Checked-In Users" bordered={false}>
                            {getStats(registeredUsers, graphSelectedDay).totalCheckedInUsers}
                          </Card>
                        </Col>
                        {Object.entries(labelStats).map(([label, stats]) => (
                          <Col span={6} key={`label-${label}`}>
                            <Card title={`Label: ${label || 'No Label'}`} bordered={false}>
                              {`${stats.total} (Check-In: ${stats.checkIn}/${stats.total})`}
                            </Card>
                          </Col>
                        ))}
                        {(Array.isArray(roundStats) ? roundStats : []).map((stats, index) => (
                          <Col span={6} key={`round-${index + 1}`}>
                            <Card title={`รอบ ${index + 1}`} bordered={false}>
                              {`${stats.total} (Check-In: ${stats.checkIn}/${stats.total})`}
                            </Card>
                          </Col>
                        ))}
                      </Row>
          
                      
          
                      <h3>Check-In Times</h3>
                      <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={getCheckInTimesData(registeredUsers, graphSelectedDay)}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="time" />
                          <YAxis />
                          <Tooltip />
                          <Line type="monotone" dataKey="count" stroke="#8884d8" />
                        </LineChart>
                      </ResponsiveContainer>
                    </>
                  );
                })()}
              </>
            ),
          },
        ]}
      />

      <Modal
        title="Send Email"
        open={showEmailModal}
        onCancel={() => setShowEmailModal(false)}
        onOk={handleSendEmail}
      >
        <p>Select Email Type:</p>
        <Select
          value={emailType}
          onChange={setEmailType}
          style={{ width: '100%', marginBottom: '10px' }}
        >
          <Option value="invitation">Invitation</Option>
          {/* Add other email types if needed */}
        </Select>

        <Checkbox
          onChange={(e) => setSendToAll(e.target.checked)}
          style={{ marginBottom: '10px' }}
        >
          Send to All
        </Checkbox>

        {!sendToAll && (
          <>
            <p>Select Label (optional):</p>
            <Select
              value={selectedLabel}
              onChange={setSelectedLabel}
              style={{ width: '100%', marginBottom: '10px' }}
              allowClear
            >
              {labelOptions.map(label => (
                <Option key={label} value={label}>{label}</Option>
              ))}
            </Select>

            <p>Select Date (optional):</p>
            <Select
              placeholder="Filter by Day"
              style={{ width: '100%', marginBottom: '10px' }}
              onChange={(value) => setSelectedDay(value)}
              allowClear
            >
              {event?.allDates.map(date => (
                <Option key={date.toISOString()} value={date.toISOString()}>
                  {formatDate_date(date)}
                </Option>
              ))}
            </Select>

            {selectedDay && (
              <>
                <p>Select Round (optional):</p>
                <Select
                  placeholder="Filter by Round"
                  style={{ width: '100%', marginBottom: '10px' }}
                  onChange={(value) => setSelectedRound(value)}
                  allowClear
                >
                  {event?.rounds &&
                    event.rounds[dayjs(selectedDay).utc().format('YYYY-MM-DD')]?.map((round, index) => (
                      <Option key={index + 1} value={index + 1}>
                        {round.start && round.end
                          ? `${formatTime(round.start)} - ${formatTime(round.end)}`
                          : 'ทั้งวัน'}
                      </Option>
                    ))}
                </Select>
              </>
            )}

            <p>Search and Select Customers:</p>
            <Select
              mode="multiple"
              placeholder="Select customers"
              value={selectedUsers}
              onChange={(selected) => setSelectedUsers(selected)}
              style={{ width: '100%', marginBottom: '10px' }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {registeredUsers.map(user => (
                <Option key={user._id} value={user._id}>
                  {`${user.name} ${user.lastName} (${user.seatNumber || 'No Seat'})`}
                </Option>
              ))}
            </Select>
          </>
        )}
      </Modal>
      <Modal
        title="Import Options"
        open={showImportModal}
        onCancel={() => setShowImportModal(false)} // Close the modal
        footer={null}
      >
        <p>Please select the type of import:</p>
        <Button type="primary" onClick={() => handleImportSelection('importUser')} style={{ marginRight: '10px' }}>
          Import Users
        </Button>
        <Button type="primary" onClick={() => handleImportSelection('importSeat')}>
          Import Seats
        </Button>
      </Modal>
      <Modal
        title="Add VIP User"
        open={showVIPModal}
        onCancel={() => setShowVIPModal(false)}
        onOk={handleAddVIP}
      >
        <p>Name:</p>
        <Input value={vipGuest.name} onChange={(e) => setVipGuest({ ...vipGuest, name: e.target.value })} />
        <p>Last Name:</p>
        <Input value={vipGuest.lastName} onChange={(e) => setVipGuest({ ...vipGuest, lastName: e.target.value })} />
        <p>Company Name (optional):</p>
        <Input value={vipGuest.company} onChange={(e) => setVipGuest({ ...vipGuest, company: e.target.value })} />
        <p>Position (optional):</p>
        <Input value={vipGuest.position} onChange={(e) => setVipGuest({ ...vipGuest, position: e.target.value })} />
        <p>Email:</p>
        <Input value={vipGuest.email} onChange={(e) => setVipGuest({ ...vipGuest, email: e.target.value })} />
        <p>Phone (optional):</p>
        <Input value={vipGuest.phone} onChange={(e) => setVipGuest({ ...vipGuest, phone: e.target.value })} />
        <p>Label (optional):</p>
        <Input value={vipGuest.label} onChange={(e) => setVipGuest({ ...vipGuest, label: e.target.value })} />

        <p>Select Dates:</p>
        <Checkbox.Group
          style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}
          onChange={(selectedDates) => setVipGuest({ ...vipGuest, date: selectedDates })}
          value={vipGuest.date}
        >
          {event?.allDates.map((date) => (
            <Checkbox key={date.toISOString()} value={date.toISOString()}>
              {formatDate_date(date)}
            </Checkbox>
          ))}
        </Checkbox.Group>

        <p>Rounds (for each selected date):</p>
        {vipGuest.date?.map((selectedDate) => (
          <div key={selectedDate} style={{ marginBottom: '10px' }}>
            <h4>{formatDate_date(selectedDate)}</h4>
            {event?.rounds && event.rounds[dayjs(selectedDate).utc().format('YYYY-MM-DD')] ? (
              <Checkbox.Group
                options={
                  event.rounds[dayjs(selectedDate).utc().format('YYYY-MM-DD')].map((round, index) => ({
                    label: `Round ${index + 1}: ${formatTime(round.start)} - ${formatTime(round.end)}`,
                    value: index + 1, // Send round index as value
                  }))
                }
                onChange={(checkedValues) =>
                  setVipGuest((prev) => ({
                    ...prev,
                    round: {
                      ...prev.round,
                      [selectedDate]: checkedValues, // Store selected round indices for this date
                    },
                  }))
                }
                value={vipGuest.round?.[selectedDate] || []}
              />
            ) : (
              <p>No rounds available for this date.</p>
            )}
          </div>
        ))}
      </Modal>

      <Modal
        title="Edit Event"
        open={showEditConfirm}
        onOk={handleConfirmEdit}
        onCancel={handleCancelEdit}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>Make sure all details are correct before confirming the changes.</p>
      </Modal>

      <Modal
        title="Delete Event"
        open={showDeleteConfirm}
        onOk={handleDeleteEvent}
        onCancel={() => setShowDeleteConfirm(false)}
        okText="Delete"
        cancelText="Cancel"
      >
        Are you sure you want to delete this event?
      </Modal>
      <Modal
        title="User Information"
        open={showUserInfoModal}
        onCancel={() => setShowUserInfoModal(false)}
        footer={[
          (userRole === 'admin' || userRole === 'staff') && (
            <Button 
              key="checkin" 
              type="primary" 
              onClick={() => handleCheckIn(selectedUser?._id)} 
              disabled={selectedUser?.checkedIn} // Disable if already checked in
            >
              {selectedUser?.checkedIn ? 'Already Checked-In' : 'Check-In'}
            </Button>
          ),
          <Button key="close" onClick={() => setShowUserInfoModal(false)}>
            Close
          </Button>,
        ]}
      >
        {selectedUser && (
          <>
            <p>ชื่อ: {`${selectedUser.name} ${selectedUser.lastName}`}</p>
            <p>เลขที่นั้ง: {selectedUser.seatNumber || 'N/A'}</p>
            <p>เบอร์มือถือ: {selectedUser.phone || 'N/A'}</p>
            <p>email: {selectedUser.email || 'N/A'}</p>
            <p>Company Name: {selectedUser.company || 'N/A'}</p>
            <p>Position: {selectedUser.position || 'N/A'}</p>
            <p>Sender: {selectedUser.sender || 'N/A'}</p>
            <p>Seat: {selectedUser.seatNumber || 'N/A'}</p>
            {selectedUser.date && renderUserRounds(selectedUser.date, event?.rounds)}
            {selectedUser.qrCode && (
              <img 
                src={`${process.env.REACT_APP_STORAGE}/${selectedUser.qrCode}`} 
                alt="QR Code" 
                style={{ width: '150px', height: '150px' }} 
              />
            )}
            
            
          </>
        )}
      </Modal>
      
    </div>
  );
};

export default EventPage;
