import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Checkbox, notification } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import '../../styles/EventRegistration.css';

const EventRegistration = () => {
  const { eventId } = useParams(); // Get eventId from the route
  const [loading, setLoading] = useState(false);
  const [eventDates, setEventDates] = useState([]);
  const [eventRounds, setEventRounds] = useState({});
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedRounds, setSelectedRounds] = useState({});
  const navigate = useNavigate(); // To navigate to success page

  // Fetch event details for dates and rounds
  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/events/${eventId}`);
        const { date, rounds } = response.data;

        // Generate all dates in the range without timezone conversion
        const startDate = dayjs(date[0]).utc().startOf('day');
        const endDate = dayjs(date[1]).utc().startOf('day');
        const allDates = [];
        let currentDate = startDate;

        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
          allDates.push(currentDate.toISOString());
          currentDate = currentDate.add(1, 'day');
        }

        setEventDates(allDates); // Dates array as continuous days
        setEventRounds(rounds); // Rounds object
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'Failed to load event details.',
        });
      }
    };

    fetchEventDetails();
  }, [eventId]);

  // Handle form submission
  const onFinish = async (values) => {
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/users/${eventId}/register`, {
        ...values,
        type: 'Walk-In',
        date: selectedDates,
        round: selectedRounds,
      });
      notification.success({
        message: 'Registration Successful',
        description: 'Thank you for registering!',
      });
      navigate(`/registration-success`); // Redirect to success page
    } catch (error) {
      notification.error({
        message: 'Registration Failed',
        description: error.response?.data?.message || 'Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (checkedValues) => {
    setSelectedDates(checkedValues);
    setSelectedRounds((prev) => {
      // Reset selected rounds for unchecked dates
      const updatedRounds = { ...prev };
      Object.keys(updatedRounds).forEach((date) => {
        if (!checkedValues.includes(date)) {
          delete updatedRounds[date];
        }
      });
      return updatedRounds;
    });
  };

  const handleRoundChange = (date, checkedValues) => {
    setSelectedRounds((prev) => ({ ...prev, [date]: checkedValues }));
  };

  const formatDate_date = (dateStr) => {
    const buddhistYear = dayjs.utc(dateStr).year() + 543; // Add 543 to the Gregorian year
    return dayjs.utc(dateStr).locale('th').format(`D MMMM ${buddhistYear}`); // Replace year with Buddhist year
  };

  const formatTime = (dateStr) => {
    if (!dateStr) return 'Invalid Time';
    return dayjs(dateStr).tz('Asia/Bangkok').format('HH:mm');
  };

  return (
    <div className="registration-container">
      <h2>Event Registration</h2>
      <Form layout="vertical" onFinish={onFinish} className="registration-form">
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input your name!' }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: 'Please input your last name!' }]}
        >
          <Input placeholder="Enter your last name" />
        </Form.Item>

        <Form.Item label="Company Name (Optional)" name="company">
          <Input placeholder="Enter your company name" />
        </Form.Item>

        <Form.Item label="Position (Optional)" name="position">
          <Input placeholder="Enter your position" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>

        <Form.Item label="Phone (Optional)" name="phone">
          <Input placeholder="Enter your phone number" />
        </Form.Item>

        {/* Date Checkboxes */}
        <Form.Item label="Select Dates">
          <Checkbox.Group
            options={eventDates.map((date) => ({
              label: formatDate_date(date),
              value: date,
            }))}
            onChange={handleDateChange}
          />
        </Form.Item>

        {/* Round Checkboxes for each selected date */}
        {selectedDates.map((date) => (
          <Form.Item key={date} label={`Select Rounds for ${formatDate_date(date)}`}>
            <Checkbox.Group
              options={
                eventRounds[dayjs(date).utc().format('YYYY-MM-DD')]?.map((round, index) => ({
                  label: `Round ${index + 1}: ${formatTime(round.start)} - ${formatTime(round.end)}`,
                  value: index + 1,
                })) || []
              }
              onChange={(checkedValues) => handleRoundChange(date, checkedValues)}
            />
          </Form.Item>
        ))}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} className="submit-button">
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EventRegistration;
