import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/RegistrationSuccess.css'; // Create a CSS file for styling

const RegistrationSuccess = () => {
  const navigate = useNavigate();


  return (
    <div className="success-container">
      <h1>🎉 Registration Successful! 🎉</h1>
      <p>
        Thank you for registering. We’re thrilled to have you join us! <br />
        <strong>See you on the event date!</strong>
      </p>
    </div>
  );
};

export default RegistrationSuccess;
