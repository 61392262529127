import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Main CSS file for date picker
import 'react-date-range/dist/theme/default.css'; // Theme CSS file
import '../../styles/eventDashboard.css'; // Custom CSS for modern design

const EventDashboard = ({ userRole }) => {
  const [events, setEvents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [newEvent, setNewEvent] = useState({ name: '', date: [], location: '', time: '', thumbnail: null });
  const [showModal, setShowModal] = useState(false);
  const [rounds, setRounds] = useState({});
  const [dateRange, setDateRange] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  }]);

  const navigate = useNavigate(); // Initialize useNavigate for navigation

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/events`,{withCredentials: true});
        setEvents(response.data);
        
      } catch (error) {
        toast.error('Error fetching events!');
        console.error('Error fetching events:', error);
      }
    };
    fetchEvents();
  }, []);
  useEffect(() => {
    const normalizedStartDate = new Date(
      dateRange[0].startDate.getFullYear(),
      dateRange[0].startDate.getMonth(),
      dateRange[0].startDate.getDate(),
      0, 0, 0, 0 // Normalize to start of the day
    );
  
    const normalizedEndDate = new Date(
      dateRange[0].endDate.getFullYear(),
      dateRange[0].endDate.getMonth(),
      dateRange[0].endDate.getDate(),
      23, 59, 59, 999 // Normalize to end of the day
    );
  
    console.log("Normalized Start Date:", normalizedStartDate);
    console.log("Normalized End Date:", normalizedEndDate);
  }, [dateRange]);

  const handleAddEvent = async () => {
    const missingFields = [];
    if (!newEvent.name) missingFields.push('ชื่อกิจกรรม');
    if (!newEvent.location) missingFields.push('สถานที่จัดกิจกรรม');
    if (!newEvent.thumbnail) missingFields.push('รูปภาพปก');
    if (!dateRange[0].startDate || !dateRange[0].endDate) missingFields.push('ช่วงวันที่');
    if (!newEvent.time) missingFields.push('เวลา');

    if (missingFields.length > 0) {
      toast.error(`กรุณากรอกข้อมูล: ${missingFields.join(', ')}`);
      return;
    }
    const formattedRounds = {};
    for (const [date, roundArray] of Object.entries(rounds)) {
      formattedRounds[date] = roundArray.map((round) => ({
        start: round.start,
        end: round.end,
      }));
    }

    const formData = new FormData();
    const encodedFileName = encodeURIComponent(newEvent.thumbnail.name);
    formData.append('thumbnail', newEvent.thumbnail, encodedFileName);
    formData.append('name', newEvent.name);
    formData.append('date', [dateRange[0].startDate.toISOString(), dateRange[0].endDate.toISOString()]);
    formData.append('location', newEvent.location);
    formData.append('time', newEvent.time);
    formData.append('rounds', JSON.stringify(formattedRounds));

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/events`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true
      });
      setEvents([...events, response.data]);
      setShowModal(false); // Close modal after success
      toast.success('เพิ่มกิจกรรมเรียบร้อยแล้ว!');
    } catch (error) {
      toast.error('เกิดข้อผิดพลาดในการเพิ่มกิจกรรม!');
      console.error('Error adding event:', error);
    }
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewEvent({ ...newEvent, thumbnail: file });
    }
  };

  const formatThaiDate = (dateStr) => {
    if (!dateStr) {
      return 'Invalid Date'; // Handle undefined or missing date values
    }
  
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
  
    // Use UTC date values to avoid timezone offsets
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth(); // Zero-based index
    const day = date.getUTCDate();
  
    // Format as Thai date
    const thaiMonths = [
      'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
      'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม',
    ];
  
    return `${day} ${thaiMonths[month]} ${year}`;
  };

  const handleEventClick = (eventId) => {
    window.location.href = `/events/${eventId}`; // Use window.location.href for a full page reload
  };

  return (
    <div className="event-dashboard">
      <header className="dashboard-header">
        <div className="header-actions">
          <input
            type="text"
            className="search-bar"
            placeholder="ค้นหากิจกรรม..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {userRole === 'admin' && ( // Only show button if userRole is 'admin'
            <button className="add-event-btn" onClick={() => setShowModal(true)}>+ เพิ่มกิจกรรม</button>
          )}
        </div>
      </header>

      <div className="events-container">
        {events.filter(event => event.name.toLowerCase().includes(searchQuery.toLowerCase())).map(event => (
          <div className="event-card" key={event._id} onClick={() => handleEventClick(event._id)}> {/* Add onClick handler */}
            <img
              src={`${process.env.REACT_APP_STORAGE}${event.thumbnail}`}
              alt="Event Thumbnail"
              className="event-thumbnail"
            />
            <div className="event-info">
              <h5 className="event-name">{event.name}</h5>
              <p className="event-date">
                ตั้งแต่: {formatThaiDate(event.date[0])} ถึง {formatThaiDate(event.date[1])}
              </p>
              <p className="event-time">description: {event.time}</p>
              <p className="event-location">สถานที่: {event.location}</p>
            </div>
          </div>
        ))}
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-header">
              <h5 className="modal-title">เพิ่มกิจกรรมใหม่</h5>
              <button className="close-modal-btn" onClick={() => setShowModal(false)}>×</button>
            </div>
            <div className="modal-body">
              <input
                type="text"
                className="input-field"
                placeholder="ชื่อกิจกรรม"
                value={newEvent.name}
                onChange={(e) => setNewEvent({ ...newEvent, name: e.target.value })}
              />
              <div className="input-field">
                <DateRangePicker
                  ranges={dateRange}
                  onChange={item => setDateRange([item.selection])}
                  staticRanges={[]} 
                  inputRanges={[]} 
                />
              </div>
              <input
                type="text"
                className="input-field"
                placeholder="สถานที่จัดกิจกรรม"
                value={newEvent.location}
                onChange={(e) => setNewEvent({ ...newEvent, location: e.target.value })}
              />
              <input
                type="text"
                className="input-field"
                placeholder="description"
                value={newEvent.time}
                onChange={(e) => setNewEvent({ ...newEvent, time: e.target.value })}
              />
              <div className="rounds-input">
                <h5 className="rounds-title">กำหนดรอบเวลาในแต่ละวัน:</h5>
                {dateRange[0].startDate && dateRange[0].endDate && (
                  Array.from(
                    {
                      length:
                        (new Date(dateRange[0].endDate).setHours(23, 59, 59, 999) -
                          new Date(dateRange[0].startDate).setHours(0, 0, 0, 0)) /
                          (1000 * 60 * 60 * 24) + 1,
                    },
                    (_, i) => {
                      const currentDate = new Date(dateRange[0].startDate);
                      currentDate.setDate(currentDate.getDate() + i);
                      const dateKey = currentDate.toISOString().split("T")[0];

                      return (
                        <div key={dateKey} className="round-date-container">
                          <div className="date-header">
                            <label className="date-label">{currentDate.toLocaleDateString("th-TH", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}</label>
                            <div className="all-day-toggle">
                              <input
                                type="checkbox"
                                id={`allDay-${dateKey}`}
                                onChange={(e) => {
                                  const newRounds = { ...rounds };
                                  if (e.target.checked) {
                                    newRounds[dateKey] = ["All Day"];
                                  } else {
                                    newRounds[dateKey] = [];
                                  }
                                  setRounds(newRounds);
                                }}
                                checked={rounds[dateKey]?.includes("All Day")}
                              />
                              <label htmlFor={`allDay-${dateKey}`}> ทั้งวัน</label>
                            </div>
                          </div>
                          {!rounds[dateKey]?.includes("All Day") && (
                            <div className="round-times">
                              {rounds[dateKey]?.map((timeRange, index) => (
                                <div key={`${dateKey}-${index}`} className="time-row">
                                  <input
                                    type="time"
                                    value={timeRange?.start || ""}
                                    onChange={(e) => {
                                      const newRounds = { ...rounds };
                                      if (!newRounds[dateKey][index]) {
                                        newRounds[dateKey][index] = { start: "", end: "" };
                                      }
                                      newRounds[dateKey][index].start = e.target.value;
                                      setRounds(newRounds);
                                    }}
                                    className="time-input"
                                  />
                                  <span>ถึง</span>
                                  <input
                                    type="time"
                                    value={timeRange?.end || ""}
                                    onChange={(e) => {
                                      const newRounds = { ...rounds };
                                      if (!newRounds[dateKey][index]) {
                                        newRounds[dateKey][index] = { start: "", end: "" };
                                      }
                                      newRounds[dateKey][index].end = e.target.value;
                                      setRounds(newRounds);
                                    }}
                                    className="time-input"
                                  />
                                  <button
                                    type="button"
                                    onClick={() => {
                                      const newRounds = { ...rounds };
                                      newRounds[dateKey] = newRounds[dateKey].filter(
                                        (_, idx) => idx !== index
                                      );
                                      setRounds(newRounds);
                                    }}
                                    className="remove-round-btn"
                                  >
                                    ลบ
                                  </button>
                                </div>
                              ))}
                              <button
                                type="button"
                                onClick={() => {
                                  const newRounds = { ...rounds };
                                  if (!newRounds[dateKey]) newRounds[dateKey] = [];
                                  newRounds[dateKey].push({ start: "", end: "" });
                                  setRounds(newRounds);
                                }}
                                className="add-round-btn"
                              >
                                เพิ่มรอบเวลา
                              </button>
                            </div>
                          )}
                        </div>
                      );
                    }
                  )
                )}
              </div>
              <div className="thumbnail-upload">
                <label htmlFor="thumbnail" className="thumbnail-label">
                  แนะนำขนาดรูปภาพ: 290x225px
                </label>
                <input
                  type="file"
                  className="input-field"
                  id="thumbnail"
                  onChange={handleThumbnailChange}
                  accept="image/*"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn-primary" onClick={handleAddEvent}>บันทึก</button>
              <button className="btn-secondary" onClick={() => setShowModal(false)}>ยกเลิก</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDashboard;
